import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  Observable,
  Subject,
  throwError,
} from 'rxjs';
import { IonicService } from './ionic.service';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { UserStore } from '../store/user.store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public STORAGE_NAME = 'user';
  public user: any;

  //User
  public loginSubject: Subject<any> = new Subject<any>();
  public updateUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.getLoggedUserFromLocalStorage(),
  );

  //UserLevel
  public userLevelSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(this.getUserLevelFromLocalStorage());
  public userLevel$ = this.userLevelSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  apiUrl = environment.apiUrl;
  userStore = inject(UserStore);

  constructor(
    private http: HttpClient,
    public ionicService: IonicService,
    public userService: UserService,
  ) { }

  getUser() {
    return this.userStore.user();
    // const storedValue = localStorage.getItem(this.STORAGE_NAME);
    // return storedValue ? JSON.parse(storedValue) : null;
  }

  login(params: any) {
    const aux = {
      email: params.email,
      password: params.password,
    };
    return this.http
      .post<any>(`${this.apiUrl}/auth/login`, aux, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(
            () => new Error(error.error.message || 'Error during login'),
          );
        }),
      );
  }

  async logout() {
    localStorage.removeItem(this.STORAGE_NAME);
    localStorage.removeItem('lang');
    this.user = null;
    this.userStore.logOut();
    this.ionicService.goPage('/home');
    // this.updateUserSubject.next(null);
    // this.userLevelSubject.next(0);
  }

  signUp(params: any) {
    return this.http.post<any>(`${this.apiUrl}/auth/register`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  loginSave(user: any, tokens: any, refreshToken: any, tokenAuth: any) {
    console.log(user);
    this.userStore.updateUser({
      ...user,
      tokens,
      refreshToken,
      tokenAuth,
    });

    this.updateUserSubject.next(user);
    this.userLevelSubject.next(user.userLevel?.userLevel);

    this.saveUserLevelToLocalStorage(user.userLevel?.userLevel);
  }

  private getUserLevelFromLocalStorage(): number {
    const storedData = localStorage.getItem(this.STORAGE_NAME);
    if (storedData) {
      const aux = JSON.parse(storedData);
      return aux?.user?.userLevel?.userLevel ?? 0;
    }
    return 0;
  }

  // public saveUserLevelToLocalStorage(userLevel: number): void {
  //   const storedData = localStorage.getItem(this.STORAGE_NAME);
  //   if (storedData) {
  //     const parsedData = JSON.parse(storedData);
  //     if (parsedData.user && parsedData.user.userLevel) {
  //       parsedData.user.userLevel.userLevel = userLevel;
  //       localStorage.setItem(this.STORAGE_NAME, JSON.stringify(parsedData));
  //     }
  //   }
  // }
  public saveUserLevelToLocalStorage(userLevel: any): void {
    const currentUser = this.getUser();

    if (currentUser && currentUser.userLevel) {
      const updatedUser = {
        ...currentUser,
        userLevel: userLevel.uuid ? { ...userLevel } : {
          ...currentUser.userLevel,
          userLevel: userLevel,
        },
      };

      this.userStore.updateUser(updatedUser);
    }
  }
  checkIfLogged() {
    return this.userStore.user();
    // return localStorage.getItem(this.STORAGE_NAME);
  }

  getLoggedUser() {
    return this.user;
  }

  public getLoggedUserFromLocalStorage(): any {
    // const storedData = localStorage.getItem(this.STORAGE_NAME);
    const storedData =
      JSON.parse(localStorage.getItem(this.STORAGE_NAME)!) ||
      this.userStore?.user()
    if (storedData) {
      // const parsedData = JSON.parse(storedData.toString());
      return storedData || null;
    }
    return null;
  }

  async getUserLevel(uuid: string) {
    this.userService.getUserLevel(uuid).subscribe((res: any) => {
      this.userLevelSubject.next(res);
    });
  }

  public updateUserPhone(phone: any): Observable<any> {
    const params = {
      phone,
      token: JSON.parse(localStorage.getItem('user')!).tokenAuth,
    };
    return this.http
      .post<any>(`${this.apiUrl}/auth/update-phone`, params, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(
            () =>
              new Error(error.error?.message || 'An unexpected error occurred'),
          );
        }),
      );
  }

  verifyUserPhone(code: string) {
    const params = {
      code,
      token: JSON.parse(localStorage.getItem('user')!).tokenAuth,
    };
    return this.http.post<any>(`${this.apiUrl}/auth/verify-phone`, params).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(
          () =>
            new Error(error.error.message || 'An unexpected error occurred'),
        );
      }),
    );
  }

  forgotPass(param: any) {
    return this.http.post<any>(`${this.apiUrl}/auth/forgot-password`, param);
  }

  registerConfirm(params: { username: string; confirmationCode: string }) {
    return this.http.post(`${this.apiUrl}/auth/register-confirm`, params);
  }

  forgotPasswordConfirm(params: {
    email: string;
    code: string;
    newPassword: string;
  }) {
    return this.http.post(
      `${this.apiUrl}/auth/confirm-forgot-password`,
      params,
    );
  }

  createSMSDocumentation(userId: string, canUpgradeToLevelOne: boolean) {
    let user = JSON.parse(localStorage.getItem('user')!);
    const params = {
      userId,
      canUpgradeToLevelOne: canUpgradeToLevelOne,
    };
    return this.http.post<any>(
      `${this.apiUrl}/user-level/create-sms-documentation`,
      params,
    );
  }

  updateUserCoins(coins: number, onlyUpdate: boolean) {
    const currentUser = this.getLoggedUserFromLocalStorage();
    if(onlyUpdate && currentUser){
      currentUser.coins = coins;
      localStorage.setItem(this.STORAGE_NAME, JSON.stringify(currentUser));
      this.userStore.updateUser(currentUser);
    }
    if (!onlyUpdate && currentUser) {
      currentUser.coins = (currentUser.coins || 0) - coins;
      localStorage.setItem(this.STORAGE_NAME, JSON.stringify(currentUser));
      this.userStore.updateUser(currentUser);
    }
  }
}
